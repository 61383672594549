// Targets for value injection during pipelines
const liveConfig = {
    ENVIRONMENT: '$CI_ENVIRONMENT_NAME',
    API_URL: '$TF_OUT_api_url',
    LLM_API_URL: '$TF_OUT_llm_api_url',
    REPORT_SVC_URL: '$TF_OUT_report_svc_url',
    AM_CHARTS_LICENSE: '$AM_CHARTS_LICENSE',
    AG_GRID_LICENSE: '$AG_GRID_LICENSE',
    DEV_TOOLS: '$DEV_TOOLS',
    LOGGING_LEVEL: '$LOGGING_LEVEL',
    LOGGING_HANDLERS: '$LOGGING_HANDLERS',
    LOGGING_GRAFANA_URL: '$LOGGING_GRAFANA_URL',
    LOGGING_GRAFANA_APP_NAME: '$LOGGING_GRAFANA_APP_NAME',
    LOGGING_RUM_ENABLED: '$LOGGING_RUM_ENABLED',
    LOGGING_TRACING_ENABLED: '$LOGGING_TRACING_ENABLED',
    AUTH_CLIENT_ID: '$TF_OUT_auth_client_id',
    AUTH_DOMAIN: '$TF_OUT_auth_domain',
    AUTH_AUDIENCE: '$TF_OUT_auth_audience',
    UNLEASH_URL: '$UNLEASH_URL',
    UNLEASH_CLIENT_KEY: '$UNLEASH_CLIENT_KEY',
    UNLEASH_REFRESH_INTERVAL: '$UNLEASH_REFRESH_INTERVAL',
    UNLEASH_APP_NAME: '$UNLEASH_APP_NAME',
    UNLEASH_ENVIRONMENT: '$UNLEASH_ENVIRONMENT',
}

const getConfigValue = (envVarName) => {
    return import.meta.env[`VITE_${envVarName}`] && import.meta.env[`VITE_${envVarName}`].trim()
        ? import.meta.env[`VITE_${envVarName}`]
        : liveConfig[envVarName]
}

export const config = {
    version: import.meta.env.PACKAGE_VERSION,
    apiUrl: getConfigValue('API_URL'),
    env: getConfigValue('ENVIRONMENT'),
    devTools: getConfigValue('DEV_TOOL'),
    auth: {
        domain: getConfigValue('AUTH_DOMAIN'),
        clientId: getConfigValue('AUTH_CLIENT_ID'),
        audience: getConfigValue('AUTH_AUDIENCE'),
    },
    logging: {
        level: getConfigValue('LOGGING_LEVEL'),
        handlers: getConfigValue('LOGGING_HANDLERS')?.split(' ') ?? ['console'],
        grafanaUrl: getConfigValue('LOGGING_GRAFANA_URL'),
        grafanaAppName: getConfigValue('LOGGING_GRAFANA_APP_NAME'),
        rumEnabled: getConfigValue('LOGGING_RUM_ENABLED') === 'true',
        tracingEnabled: getConfigValue('LOGGING_TRACING_ENABLED') === 'true',
    },
    external: {
        reportSvcUrl: getConfigValue('REPORT_SVC_URL'),
        llmApiUrl: getConfigValue('LLM_API_URL'),
    },
    unleash: {
        url: getConfigValue('UNLEASH_URL'),
        clientKey: getConfigValue('UNLEASH_CLIENT_KEY'),
        refreshInterval: getConfigValue('UNLEASH_REFRESH_INTERVAL'),
        appName: getConfigValue('UNLEASH_APP_NAME'),
        environment: getConfigValue('UNLEASH_ENVIRONMENT'),
    },
    license: {
        AMChartsLicense: getConfigValue('AM_CHARTS_LICENSE'),
        AGGridLicense: getConfigValue('AG_GRID_LICENSE'),
    },
}
